import {createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginForm from "../components/LoginForm.vue";
import RegisterForm from "../components/RegisterForm.vue";
import LogoutRedirect from "../components/LogoutRedirect.vue";
import Level1 from "../views/Level1.vue";
import Level2 from "../views/Level2.vue";
import Level3 from "../views/Level3.vue";
import Level4 from "../views/Level4.vue";
import Level5 from "../views/Level5.vue";
import Level6 from "../views/Level6.vue";
import Level7 from "../views/Level7.vue";

import SongList from "../views/SongList.vue";
import AboutView from "../views/AboutView.vue";
import AddSongs from "../views/AddSongs.vue";
import UserList from "../views/UserList.vue";
import TestView from "../views/TestView.vue";
import TestEmbed from "@/views/TestEmbed.vue";
import Unavailable from "@/views/Unavailable.vue";
import {EmptyStoreToLogin} from "@/middleware/EmptyStoreToLogin";
import Export from "@/views/Export.vue";
import ChangeLog from "@/views/ChangeLog.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView
    },
    {
        path: "/about",
        name: "About",
        component: AboutView,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginForm
    },
    {
        path: "/register",
        name: "Register",
        component: RegisterForm
    },
    {
        path: "/logout",
        name: "Logout",
        component: LogoutRedirect
    },
    {
        path: "/level1",
        name: "Level1",
        component: Level1
    },
    {
        path: "/level2",
        name: "Level2",
        component: Level2
    },
    {
        path: "/level3",
        name: "Level3",
        component: Level3
    },
    {
        path: "/level4",
        name: "Level4",
        component: Level4
    },
    {
        path: "/level5",
        name: "Level5",
        component: Level5
    },
    {
        path: "/level6",
        name: "Level6",
        component: Level6
    },
    {
        path: "/level7",
        name: "Level7",
        component: Level7
    },

    {
        path: "/songlist",
        name: "SongList",
        component: SongList,
        beforeEnter: [EmptyStoreToLogin]
    },
    {
        path: "/addsongs",
        name: "AddSongs",
        component: AddSongs,
        beforeEnter: [EmptyStoreToLogin]
    },
    {
        path: "/userlist",
        name: "UserList",
        component: UserList,
        beforeEnter: [EmptyStoreToLogin]
    },
    {
        path: "/testview",
        name: "TestView",
        component: TestView,
        beforeEnter: [EmptyStoreToLogin]
    },
    {
        path: "/testEmbed/:videoId",
        name: "TestEmbed",
        component: TestEmbed,
        beforeEnter: [EmptyStoreToLogin]
    },
    {
        path: "/unavailable",
        name: "Unavailable",
        component: Unavailable,
        beforeEnter: [EmptyStoreToLogin]
    },
    {
        path: "/db",
        component: {template: "<div></div>"},
    },
    {
        path: "/export",
        component: Export,
    },
    {
        path: "/changelog",
        component: ChangeLog,
        name: "ChangeLog"
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;