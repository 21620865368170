import axios from "axios";
import Url from "@/models/Url";
import router from "@/router";
import {
    AllUserResponse,
    Level1Response,
    Level2Response,
    Level3Response,
    Level4Response,
    Level5Response,
    LevelError,
    LoginResponse,
    Song
} from "@/models/interface";
import {Availability, Tonality} from "@/models/Constants";

const setAuthHeader = () => {
    const storage = localStorage.getItem("vuex");
    if (storage !== null) {
        const data = JSON.parse(storage);
        if (data.user !== null) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + data.user.token;
            return "Bearer " + data.user.token;
        } else {
            router.push("/login");
        }
    } else {
        throw new Error("Auth header cannot be set");
    }
};

const fetchData = async (path: string, config = {}) => {
    try {
        setAuthHeader();
        const response = await axios(path, config);
        return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
            router.push("/login");
        }
        throw error;
    }
};

export const GetLevel1Video = async (tonalityFilter: Tonality): Promise<Level1Response[] | LevelError> => {
    const result = await fetchData(Url.level1VideosPath, {
        method: "post",
        data: {tonality: tonalityFilter},
    });

    if (result.length == 0) throw Error("Not found");

    return result;
};

export const GetLevel2Video = async (): Promise<Level2Response[]> => {
    return await fetchData(Url.level2VideosPath);
};

export const GetLevel3Video = async (): Promise<Level3Response> => {
    return await fetchData(Url.level3VideosPath);
};

export const GetLevel4Video = async (): Promise<Level4Response> => {
    return await fetchData(Url.level4VideosPath);
};

export const GetLevel5Video = async (): Promise<Level5Response> => {
    return await fetchData(Url.level5VideosPath);
};

export const GetUnavailableSongs = async (): Promise<Song[]> => {
    return await fetchData(Url.unavailableSongs);
};

export const GetAllSong = async (): Promise<Song[]> => {
    return await fetchData(Url.getAllSongPath);
};

export const AddSongs = async (songs: string[]): Promise<Song> => {
    return await fetchData(Url.addSongPath, {
        method: "post",
        data: {titles: songs}
    });
};

export const ReportAsBroken = async (videoId: string): Promise<boolean> => {
    console.log("Services - Api - ReportAsBroken");
    await fetchData(Url.reportAsBrokenPath, {
        method: "post",
        data: {
            guid: videoId,
            availability: 1,
        }
    });
    return true;
};

export const CreateHistory = async (level: number, guid: string): Promise<boolean> => {
    await fetchData(`/api/Level${level}/${guid}`, {
        method: "post"
    });
    return true;
};

export const VerifyLevel2Tonality = async (guid: string, tonality: Tonality): Promise<boolean> => {
    const response = await fetchData(Url.verifyLevel2TonalityPath, {
        method: "post",
        data: {
            guid: guid,
            tonality: tonality
        }
    });
    return response.status === 200;
};

export const OnlyVerifyTonality = async (guid: string, tonality: Tonality): Promise<boolean> => {
    const response = await fetchData(Url.onlyVerifyTonality, {
        method: "post",
        data: {
            guid: guid,
            tonality: tonality
        }
    });
    return response.status === 200;
};


export const VerifyLevel3Tonality = async (guid: string, tonality: Tonality): Promise<boolean> => {
    const response = await fetchData(Url.verifyLevel3TonalityPath, {
        method: "post",
        data: {
            guid: guid,
            tonality: tonality
        }
    });
    return response.status === 200;
};

export const VerifyLevel4Tonality = async (guid: string, tonality: Tonality): Promise<boolean> => {
    const response = await fetchData(Url.verifyLevel4TonalityPath, {
        method: "post",
        data: {
            guid: guid,
            tonality: tonality
        }
    });
    return response.status === 200;
};

export const CreateLevel3History = async (guid: string): Promise<boolean> => {
    const response = await fetchData(Url.verifyLevel3BaseNotePath, {
        method: "post",
        data: {
            guid: guid,
        }
    });
    return response.status === 200;
};

export const CreateLevel4History = async (guid: string): Promise<boolean> => {
    const response = await fetchData(Url.verifyLevel4BaseNotePath, {
        method: "post",
        data: {
            guid: guid
        }
    });
    return response.status === 200;
};
export const CreateLevel5History = async (guid: string): Promise<boolean> => {
    const response = await fetchData(Url.verifyLevel5BaseNotePath, {
        method: "post",
        data: {
            guid: guid
        }
    });
    return response.status === 200;
};


export const ResetTonality = async (tonality: number): Promise<boolean> => {
    const response = await fetchData(Url.resetLvl1Tonality, {
        method: "post",
        data: {
            tonality: tonality
        }
    });
    return response.status === 200;
};

export const ResetHistory = async (level: number): Promise<boolean> => {
    const response = await fetchData(`/api/Level${level}`, {
        method: "delete"
    });
    return response.status === 200;
};

export const SetSongAvailability = async (available: Availability, guid: string): Promise<boolean> => {
    const response = await fetchData(Url.songAvailability, {
        method: "post",
        data: {
            guid: guid,
            availability: available
        }
    });
    return response.status == 200;
};


export const Login = async (email: string, password: string): Promise<LoginResponse> => {
    // Do not put this through fetchData becuse Error responses will not be checked
    const data = {
        email: email,
        password: password
    };

    const result = await axios.post(Url.loginPath, data);

    if (result.status !== 200) {
        throw new Error();
    }

    return result.data;
};

export const Register = async (email: string, username: string, password: string): Promise<boolean> => {
    // Do not put this through fetchData becuse Error responses will not be checked
    const data = {
        email: email,
        password: password,
        username: username
    };
    const result = await axios.post(Url.registerPath, data);
    if (result.status !== 201) {
        throw new Error(result.data);
    }
    return true;
};

export const GetAllUsers = async (): Promise<AllUserResponse> => {
    return await fetchData(Url.usersPath);
};

export const DeleteSong = async (guid: string): Promise<boolean | Error> => {
    await fetchData(Url.deleteSongPath + guid, {
        method: "delete"
    });
    return true;
};

export const UpdateSong = async (song: Song): Promise<boolean | Error> => {
    const response: Song = await fetchData(Url.editVideoPath, {
        method: "put",
        data: song
    });
    if (response && typeof response === "object" && "videoId" in response) {
        return true;
    }
    throw new Error();
};

export const Export = async () => {
    return await fetchData(Url.exportPath, {
        method: "get"
    });
};