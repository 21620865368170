import userStore from "@/store/store";
import {RouteLocationNormalized} from "vue-router";
import router from "@/router"; // Import Router type

export const EmptyStoreToLogin = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any
) => {
    // this middleware will redirect the user to the login page if
    // the user store is empty (logged off)

    if (userStore.getters["user"] === null) {
        console.log("User data is not available and treated as a logged-off user");
        router.push("/login"); // Use router from the argument
    }

    next();
};
