<template>
  <MenuBar />
  <router-view />
  <FooterBar />
</template>

<style lang="scss">
html {
  margin: 0;
  padding: 0;
  background: var(--blue-900);
}

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.p-steps-item {
  z-index: 0;
}
</style>

<script>
import MenuBar from "./components/MenuBar.vue";
import {mapGetters} from "vuex";
import userStore from "@/store/store";
import FooterBar from "@/components/FooterBar.vue";

const noteLangItem = "noteLanguage";
const defaultNoteLangItem = JSON.stringify({language: "uk"});
const localStorageItem = localStorage.getItem(noteLangItem);
if (!localStorageItem) {
  localStorage.setItem(noteLangItem, defaultNoteLangItem);
  userStore.commit("setDefaultStorage", JSON.parse(defaultNoteLangItem));
  userStore.commit("noteLanguage", "uk");
} else {
  const localStore = JSON.parse(localStorage.getItem(noteLangItem));
  userStore.commit("noteLanguage", localStore.language);
}

export default {
  components: {
    FooterBar,
    MenuBar
  },
  data() {

    return {
      steps: [{
        label: "level I",
        to: "/level1",
      },
        {
          label: "Level II",
          to: "/level2"
        },
        {
          label: "Level III",
          to: "/level3"
        },
        {
          label: "Level IV",
          to: "/level4"
        },
        {
          label: "Level V",
          to: "/level5"
        },
        {
          label: "Level VI",
          to: "/level6"
        }
      ]
    };

  },
  computed: {
    ...mapGetters(["user"])
  },
};
</script>
