import {Ref, ref, UnwrapRef} from "vue";
import {Note, Tonality} from "@/models/Constants";
import {ToastServiceMethods} from "primevue/toastservice";
import {toastIt} from "@/Utils";
import {ReportAsBroken, ResetHistory} from "@/Service/Api";
import YouTube from "vue3-youtube";

const youtube = ref(YouTube);
const waitingForGuess: Ref<UnwrapRef<boolean>> = ref<boolean>(false);
const videoGuid: Ref<UnwrapRef<string>> = ref<string>("");
const videoId: Ref<UnwrapRef<string>> = ref<string>("");
const nextVideoEmptySongList: Ref<UnwrapRef<boolean>> = ref<boolean>(false);
const correctNote: any = ref<any>(0);
const playerVisible: Ref<UnwrapRef<boolean>> = ref<boolean>(true);

export const useBaseNoteComposable = (toast: ToastServiceMethods) => {

    const GetNextVideo = async (getVideoFn: Function, createHistoryFn: Function): Promise<void> => {
        waitingForGuess.value = true;
        try {
            if (videoGuid.value !== "") {
                await createHistoryFn(videoGuid.value);
            }

            const data = await getVideoFn();

            videoId.value = data.videoId;
            videoGuid.value = data.guid;
            nextVideoEmptySongList.value = false;
            console.log("nextVideoEmpyList: ", nextVideoEmptySongList.value);
            correctNote.value = data.note;
        } catch (e) {
            if ((e as Error).message.includes("404")) {
                toastIt(toast, "error", "Az összes dalt meghallgattad", 3000);
                nextVideoEmptySongList.value = true;
            }
        }
    };

    const ReportBroken = async (getNextVideoFn: Function, omitRegisterSend: boolean = true): Promise<void> => {
        const response: boolean = await ReportAsBroken(videoGuid.value);
        if (!response) {
            toastIt(
                toast,
                "error",
                `${videoId.value} nem regisztrálható elérhetőként`,
                3000
            );
        } else {
            toastIt(
                toast,
                "error",
                `https://www.youtube.com/watch?v=${videoId.value} már nem elérhető`,
                10000
            );
        }
        await getNextVideoFn(omitRegisterSend);
    };

    const ResetLevelHistory = async (getNextVideoFn: Function, resetLevel: number): Promise<void> => {
        // only used in lvl 3-4-5
        // try {
        await ResetHistory(resetLevel);
        const result = await getNextVideoFn();
        videoId.value = result.videoId;
        correctNote.value = result.note;
        nextVideoEmptySongList.value = false;
        // } catch (e) {
        //     toastIt(toast, "error", (e as Error).message, 3000);
        //     nextVideoEmptySongList.value = true;
        // }
    };

    const GuessTonality = async (verifyTonalityFn: Function, tonality: Tonality): Promise<void> => {
        waitingForGuess.value = false;
        try {
            await verifyTonalityFn(videoGuid.value, tonality);
            toastIt(toast, "success", "Helyes válasz!", 3000);
        } catch (e) {
            toastIt(toast, "error", "Nem...", 3000);
        }
    };

    const ChangeVisibility = (): void => {
        playerVisible.value = !playerVisible.value;
    };

    const Initialize = (): void => {
        videoGuid.value = "";
        videoId.value = "";
        correctNote.value = Note.Uncategorized;
        playerVisible.value = true;
        waitingForGuess.value = true;
        nextVideoEmptySongList.value = false;
    };

    return {
        youtube,
        waitingForGuess,
        videoGuid,
        videoId,
        nextVideoEmptySongList,
        correctNote,
        playerVisible,
        GetNextVideo,
        ReportBroken,
        ResetLevelHistory,
        GuessTonality,
        ChangeVisibility,
        Initialize
    };
};