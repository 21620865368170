<script lang="ts" setup>

</script>

<template>
  <card class="card">
    <template #title>
      Módosítások
    </template>
    <template #content>
      <ul class="log">
        <li>
          2024-05-27 Hétfő
          <ul>
            <li>Összes dal: Dalok megjelenítése ábécé sorrendben</li>
            <li>
              Összes dal: <a href="/export">Biztonsági mentés</a> funkció, mely listázza az adatbázisban szereplő dalok
              oly módon, hogy azok
              beilleszthetők legyenek a "Dalok Hozzáadása" menüpontban
            </li>
            <li>Biztonsági mentés: Töltés indikátor</li>
            <li>
              Szint 1 Optimalizálások:
              <ul>
                <li>A dalok kiszolgálása egysével történik (Az előző implementáció 100 dalt küldött egyszerre)</li>
                <li>A dal rögzítve a tanulónak lejátszottként abban a pillanatban, amikor a videó betöltődik</li>
                <li>Töltés-indikátor</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </template>
  </card>
</template>

<style lang="scss" scoped>
.card {
  margin-left: 15px;
  margin-right: 15px;
}

.log {
  line-height: 2;
}
</style>