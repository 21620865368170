import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import YouTube from "vue3-youtube";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Menubar from "primevue/menubar";
import Avatar from "primevue/avatar";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Divider from "primevue/divider";
import Steps from "primevue/steps";
import Slider from "primevue/slider";
import SelectButton from "primevue/selectbutton";
import MegaMenu from "primevue/megamenu";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Message from "primevue/message";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Inplace from "primevue/inplace";
import Row from "primevue/row";
import Dropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import OverlayPanel from "primevue/overlaypanel";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import SplitButton from "primevue/splitbutton";
import InputSwitch from "primevue/inputswitch";
import ProgressSpinner from "primevue/progressspinner";
import ProgressBar from "primevue/progressbar";

import axios from "axios";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
//import "primevue/resources/themes/nova-vue/theme.css"; //theme
//import "primevue/resources/themes/lara-dark-indigo/theme.css";
import "primevue/resources/primevue.min.css"; //core css";
//import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
//import "primevue/resources/themes/bootstrap4-light-purple/theme.css";
//import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
//import "primevue/resources/themes/bootstrap4-dark-purple/theme.css";
//import "primevue/resources/themes/lara-light-blue/theme.css";
//import "primevue/resources/themes/lara-light-indigo/theme.css";
//import "primevue/resources/themes/lara-light-purple/theme.css";
//import "primevue/resources/themes/lara-light-teal/theme.css";
//import "primevue/resources/themes/lara-dark-blue/theme.css";
//import "primevue/resources/themes/lara-dark-indigo/theme.css";
//import "primevue/resources/themes/lara-dark-purple/theme.css";
//import "primevue/resources/themes/lara-dark-teal/theme.css";
//import "primevue/resources/themes/soho-light/theme.css";
//import "primevue/resources/themes/saga-blue/theme.css";
//import "primevue/resources/themes/saga-green/theme.css";
//import "primevue/resources/themes/saga-orange/theme.css";
//import "primevue/resources/themes/saga-purple/theme.css";
//import "primevue/resources/themes/vela-blue/theme.css";
//import "primevue/resources/themes/vela-green/theme.css";
//import "primevue/resources/themes/vela-orange/theme.css";
//import "primevue/resources/themes/vela-purple/theme.css";
import "primevue/resources/themes/arya-blue/theme.css";
//import "primevue/resources/themes/arya-green/theme.css";
//import "primevue/resources/themes/arya-orange/theme.css";
//import "primevue/resources/themes/arya-purple/theme.css";


axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
if (localStorage.getItem("vuex") !== null) {
    const storage = JSON.parse(localStorage.getItem("vuex") || "");
    // @ts-ignore
    if (storage.user !== null) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + storage.user.token;
    }
}

// const queryParameters = new URLSearchParams(window.location.search);
// const combinedQueryParams: any = [];
//
// // Iterate through query parameters and combine them
// queryParameters.forEach((value, key) => {
//     combinedQueryParams.push(`${key}=${value}`);
// });
//
// // Join the combined parameters into a single string
// export const combinedQueryParamString = "?" + combinedQueryParams.join("&");
// console.log(combinedQueryParamString);


const app = createApp(App);


app.component("Card", Card);
app.component("InputText", InputText);
// eslint-disable-next-line vue/no-reserved-component-names
app.component("Button", Button);
app.component("Menubar", Menubar);
app.component("PassWord", Password);
app.component("Avatar", Avatar);
app.component("Steps", Steps);
app.component("Slider", Slider);
app.component("SelectButton", SelectButton);
app.component("Divider", Divider);
app.component("MegaMenu", MegaMenu);
app.component("ConfirmPopup", ConfirmPopup);
app.component("Divider", Divider);
app.directive("tooltip", Tooltip);
app.component("Inplace", Inplace);
app.component("SplitButton", SplitButton);
app.component("InputSwitch", InputSwitch);
app.component("ProgressSpinner", ProgressSpinner);
app.component("ProgressBar", ProgressBar);


app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Dropdown", Dropdown);
// eslint-disable-next-line vue/no-reserved-component-names
app.component("Textarea", Textarea);
app.component("FileUpload", FileUpload);
app.component("OverlayPanel", OverlayPanel);
app.component("Toast", Toast);
app.component("Message", Message);

app.use(store);
app.use(router);
// @ts-ignore
app.use(YouTube);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);

app.mount("#app");