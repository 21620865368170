import {Ref, ref} from "vue";
import YouTube from "vue3-youtube";
import {ToastServiceMethods} from "primevue/toastservice";
import {BasicScale} from "@/models/interface";
import {toastIt} from "@/Utils";

const youtube = ref(YouTube);
const correctScale: Ref<any> = ref();
const videoId: Ref<string | undefined> = ref<string>();

export const useScaleComposable = (toast: ToastServiceMethods) => {

    const PickRandomScale = (basicScales: BasicScale[]): boolean => {
        if (videoId.value !== "") {
            const removedExistingScale: BasicScale[] = basicScales.filter((item: BasicScale) => item.url !== videoId.value && item.id <= 6);
            const randomIndex: number = Math.floor(Math.random() * removedExistingScale.length);
            correctScale.value = removedExistingScale[randomIndex];
            videoId.value = removedExistingScale[randomIndex].url;
            return true;
        }

        const randomIndex: number = Math.floor(Math.random() * basicScales.length);
        correctScale.value = basicScales[randomIndex];
        videoId.value = basicScales[randomIndex].url;
        return false;
    };

    const ValidateCorrectScale = async (id: number, basicScales: BasicScale[]): Promise<void> => {
        if (id === correctScale.value.id) {
            toastIt(toast, "success", "Helyes válasz", 3000);
            PickRandomScale(basicScales);
            await new Promise(r => setTimeout(r, 1500));
        } else {
            toastIt(toast, "error", "Nem...", 3000);
        }
    };

    return {
        youtube,
        correctScale,
        videoId,
        PickRandomScale,
        ValidateCorrectScale
    };
};