import {BasicScale, ButtonMember, NoteModel} from "@/models/interface";
import {ref} from "vue";
import {GenreType, Note, ScaleVideos, Tonality} from "@/models/Constants";

export const NoteOptions = [
    {label: "-", value: Note.Uncategorized},
    {label: "C", value: Note.C},
    {label: "Desz", value: Note.Db},
    {label: "D", value: Note.D},
    {label: "Esz", value: Note.Eb},
    {label: "E", value: Note.E},
    {label: "F", value: Note.F},
    {label: "Fisz", value: Note.Fs},
    {label: "G", value: Note.G},
    {label: "Asz", value: Note.Ab},
    {label: "A", value: Note.A},
    {label: "B", value: Note.B},
    {label: "H", value: Note.H},
];

export const TonalityOptions = [
    {label: "-", value: Tonality.Uncategorized},
    {label: "Dúr", value: Tonality.Major},
    {label: "Moll", value: Tonality.Minor}
];

export const GenreOptions = [
    {label: "-", value: GenreType.Uncategorized},
    {label: "Magyar", value: GenreType.Hungarian},
    {label: "Klasszikus", value: GenreType.Classical},
    {label: "Angol", value: GenreType.English},
];

export const GetGenre = (genre: number) => {
    switch (genre) {
        case 0:
            return "-";
        case 1:
            return "Magyar";
        case 2:
            return "Klasszikus";
        case 3:
            return "Angol";
    }
};

export const GetTonality = (tonality: number) => {
    switch (tonality) {
        case 0:
            return "-";
        case 1:
            return "Dúr";
        case 2:
            return "Moll";
    }
};

export const GetBaseNote = (note: number) => {
    switch (note) {
        case 0:
            return "-";
        case 1:
            return "C";
        case 2:
            return "Desz";
        case 3:
            return "D";
        case 4:
            return "Esz";
        case 5:
            return "E";
        case 6:
            return "F";
        case 7:
            return "Fisz";
        case 8:
            return "G";
        case 9:
            return "Asz";
        case 10:
            return "A";
        case 11:
            return "B";
        case 12:
            return "H";
        default:
            return "N/A";
    }
};

export const Notes: NoteModel = [
    {
        importantNotes: [Note.F, Note.G, Note.A],
        name: Note.C,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.Fs, Note.Ab, Note.B],
        name: Note.Db,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.G, Note.A, Note.H],
        name: Note.D,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.Ab, Note.B, Note.C],
        name: Note.Eb,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.A, Note.H, Note.Db],
        name: Note.E,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.B, Note.C, Note.D],
        name: Note.F,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.H, Note.Db, Note.Eb],
        name: Note.Fs,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.C, Note.D, Note.E],
        name: Note.G,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.Db, Note.Eb, Note.F],
        name: Note.Ab,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.D, Note.E, Note.Fs],
        name: Note.A,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.E, Note.Fs, Note.Ab],
        name: Note.H,
        type: Tonality.Major
    },
    {
        importantNotes: [Note.Uncategorized, Note.Uncategorized, Note.Uncategorized],
        name: Note.Uncategorized,
        type: Tonality.Uncategorized
    },
];

export const NoteButtons = () => ref<Array<ButtonMember>>([
    {name: "C", id: Note.C, isImportant: false},
    {name: "Desz", id: Note.Db, isImportant: false},
    {name: "D", id: Note.D, isImportant: false},
    {name: "Esz", id: Note.Eb, isImportant: false},
    {name: "E", id: Note.E, isImportant: false},
    {name: "F", id: Note.F, isImportant: false},
    {name: "Fisz", id: Note.Fs, isImportant: false},
    {name: "G", id: Note.G, isImportant: false},
    {name: "Asz", id: Note.Ab, isImportant: false},
    {name: "A", id: Note.A, isImportant: false},
    {name: "B", id: Note.B, isImportant: false},
    {name: "H", id: Note.H, isImportant: false},
]);

export const BasicScalesForLevel6: BasicScale[] = [
    {value: "Dúr", url: ScaleVideos.Ionian, id: 1},
    {value: "Dór", url: ScaleVideos.Dorian, id: 2},
    {value: "Fríg", url: ScaleVideos.Phrygian, id: 3},
    {value: "Líd", url: ScaleVideos.Lydian, id: 4},
    {value: "Mixolíd", url: ScaleVideos.Mixolydian, id: 5},
    {value: "Moll", url: ScaleVideos.Aeolian, id: 6},
    {value: "Lokriszi", url: ScaleVideos.Locarian, id: 7},
];

export const BasicScalesForLevel7: BasicScale[] = [
    ...BasicScalesForLevel6,
    {value: "Dúr pentaton", url: ScaleVideos.Major_pentatonic, id: 8},
    {value: "Domináns fríg", url: ScaleVideos.Dominant_Phrygian, id: 9},
    {value: "Harmonikus moll", url: ScaleVideos.Harmonic_Minor, id: 10},
    {value: "Moll pentaton", url: ScaleVideos.Minor_pentatonic, id: 11},
];
