const Url = {
    level1VideosPath: "/api/Level1",
    level2VideosPath: "/api/Level2",
    level3VideosPath: "/api/Level3",
    level4VideosPath: "/api/Level4",
    level5VideosPath: "/api/Level5",
    reportAsBrokenPath: "/api/Song/availability",
    onlyVerifyTonality: "/api/Level2/onlyVerifyTonality",
    verifyLevel2TonalityPath: "/api/Level2/verify",
    verifyLevel3TonalityPath: "/api/Level3/verify",
    verifyLevel4TonalityPath: "/api/Level4/verify",
    verifyLevel3BaseNotePath: "/api/Level3",
    verifyLevel4BaseNotePath: "/api/Level4",
    verifyLevel5BaseNotePath: "/api/Level5",
    resetLvl1Tonality: "/api/Level1/deleteTonality",
    resetLvl2Tonality: "/api/Level2",
    resetLvl3tonality: "/api/Level3",
    resetLvl4Tonality: "/api/Level4",
    resetLvl5Tonality: "/api/Level5",
    unavailableSongs: "/api/Song/unavailable",
    addSongPath: "/api/Song/ImportMany",
    songAvailability: "/api/Song/availability",
    deleteSongPath: "/api/Song/",
    getAllSongPath: "/api/Song/",
    editVideoPath: "/api/Song/",
    loginPath: "/api/Auth/login",
    registerPath: "/api/Auth/register",
    usersPath: "/api/User",
    exportPath: "/api/Song/Export"
};

export default Url;
