import {createStore} from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

const userStore = createStore({
    state: {
        user: null,
        noteLanguage: null
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        noteLanguage: (state) => {
            return state.noteLanguage;
        }
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        noteLanguage(state, noteLanguage) {
            state.noteLanguage = noteLanguage;
            const defaultNoteLangItem = JSON.stringify({language: noteLanguage});
            localStorage.setItem("noteLanguage", defaultNoteLangItem);

        },
        setDefaultStorage(state, defaultLang) {
            const defaultNoteLangItem = JSON.stringify(defaultLang);
            localStorage.setItem("noteLanguage", defaultNoteLangItem);
        }
    },
    actions: {
        user(context, user) {
            context.commit("user", user);
        },
    },
    modules: {},
    plugins: [vuexLocal.plugin]
});

export default userStore;
