<template>
  <Card>
    <template #content>
      <div class="grid grid-nogutter surface-section text-800 container">
        <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
          <section class="container">
            <span class="block text-5xl font-bold mb-1">Hallásfejlesztés,</span>
            <div class="text-5xl text-primary font-bold mb-3">ahogy eddig még nem láttad</div>
            <p class="mt-0 mb-4 text-700 line-height-3">
              Üdvözöllek a hallásfejlesztés programunkban. Itt fejlesztheted a hallásod az interaktív
              és felhasználóbarát
              platformunkon keresztül. Valódi anyagokon gyakorolhatod a tonalitások, alaphangok és skálatípusok
              felismerését. Legyél kezdő vagy haladó zenész, a gyakorlataink segítenek elérni zenei céljaidat a lehető
              legrövidebb idő alatt.
            </p>

            <a :href="'/login'" class="p-button mr-3 p-button-raised" style="text-decoration: none;">Bejelentkezés</a>
            <a :href="'/register'" class="p-button p-button-outlined" style="text-decoration: none;">Regisztráció</a>
          </section>
        </div>
        <div class="col-12 md:col-6 overflow-hidden">
          <img :src="require('@/assets/gitar.jpeg')" alt="Image" class="md:ml-auto block md:h-full image"
               style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%); height: 300px; width: 700px;">
        </div>
      </div>
    </template>
  </Card>
</template>

<style lang="scss" scoped>
.container {
  border-radius: 5px;
}

.p-card {
  min-width: 618px;
}


.image {
  border-radius: 5px;
}

.p-card {
  text-align: center;
  width: 75%;
  margin: auto;
}
</style>