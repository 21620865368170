export const ScaleVideos = {
    Ionian: "SnPwJ1BYm_Y",
    Dorian: "5eZcSX4uBQ4",
    Phrygian: "AwSrsuns5W8",
    Lydian: "U4FZhjPJfgE",
    Mixolydian: "PpEM3SYzQiI",
    Aeolian: "IlVk3dcXHGE",
    Locarian: "ihYnGrRzhlE",
    Major_pentatonic: "LCw4WVVszBo",
    Dominant_Phrygian: "WxGRNEFxY0",
    Harmonic_Minor: "o90jLbO4MRs",
    Minor_pentatonic: "KRn0YP29DFw"
};

export enum Availability {
    Available,
    Unavailable
}

export enum EmptyHistoryTonality {
    All,
    Major,
    Minor
}

export enum GenreType {
    Uncategorized,
    Hungarian,
    Classical,
    English
}

export enum Note {
    Uncategorized,
    C,
    Db,
    D,
    Eb,
    E,
    F,
    Fs,
    G,
    Ab,
    A,
    B,
    H
}

export enum ScaleType {
    Uncategorized,
    Ionian,
    Dorian,
    Phrygian,
    Lydian,
    Mixolydian,
    Aeloian,
    Locrian
}

export enum Tonality {
    Uncategorized,
    Major,
    Minor
}

export enum UserRole {
    User,
    Admin
}

export enum UserStatus {
    Inactive,
    Active,
    Blocked,
    Hidden
}

export enum VerifyTonalityType {
    Major,
    Minor
}
